.Footer {
  height: 5vh;
  background-color: #6b8593;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Footer > div {
  filter: drop-shadow(1px 1px 1px #222);
  text-align: center;
  margin: 3em;
}

@media (width < 768px) {
  .Footer > div {
    font-size: 12px;
    margin: 1em;
  }
}
