.title_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 2vw 2vw 10vw 2vw;
}

.title {
  filter: drop-shadow(5px 5px 5px #222);
}

.title img {
  width: 50vw;
}

.subtitle {
  font-size: 16px;
  color: white;
  text-wrap: balance;
  text-align: center;
  filter: drop-shadow(2px 2px 2px #222);
}

.titleWishlistButton {
  margin-top: 2vh;
}

@media (width > 768px) {
  .title img {
    height: 10vw;
    width: auto;
  }
}

/* large screens */
@media (width > 1280px) {
  .title img {
    height: 200px;
    width: auto;
  }

  .subtitle {
    font-size: 1.5rem;
    margin-top: 20px;
  }
  .titleWishlistButton {
    display: none;
  }
}
