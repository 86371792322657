body {
  margin: 0;
  background-color: #6b8593;
  background-color: white;
  font-family: Lato;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: Lato;
  src: url(../../public/Lato-Regular.ttf);
}

body {
  margin: 0;
  padding: 0;
}
