.objectiveList {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.objective {
  background-size: cover;
  background-position: center;
  padding: 10%;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: stretch;
  text-align: center;
}

.name {
  font-size: 30px;
  filter: drop-shadow(2px 2px 2px #222);
  text-wrap: balance;
}

.desc {
  font-size: 12px;
  filter: drop-shadow(2px 2px 2px #222);
  text-wrap: balance;
}

/* tablet screens */
@media (480px < width <= 768px) {
  .name {
    font-size: 40px;
  }

  .desc {
    font-size: 16px;
  }
}

/* medium screens */
@media (769px < width <= 1279px) {
  .objective {
    text-align: left;
    justify-content: center;
    background-position: right;
  }
  .objective:nth-child(even) {
    text-align: right;
    background-position: left;
  }
  .name {
    font-size: 50px;
  }

  .desc {
    font-size: 20px;
  }
}

/* large screens */
@media (width >= 1280px) {
  .objective {
    text-align: left;
    justify-content: center;
    background-position: right;
  }
  .objective:nth-child(even) {
    text-align: right;
    background-position: left;
  }
  .name {
    font-size: 70px;
  }

  .desc {
    font-size: 24px;
  }
}
