.Video {
  display: flex;
  height: 50vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.Video > iframe {
  width: 60%;
}
