.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.containerWithTitle {
  text-align: left;
}

.presskit {
  background-color: white;
  display: flex;
  justify-content: center;
  gap: 15px;
  max-width: 1200px;
}

.presskit h1 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.presskit div {
  font-size: 1em;
}

.navigation {
  min-width: 30%;
}

.navigation nav ul {
  list-style: none;
  padding: 0;
}

.navigation nav ul a {
  text-decoration: none;
  color: black;
  display: block;
  padding: 10px 15px;
}
.navigation nav ul a:hover {
  background-color: #00000080;
}

.content {
  width: auto;
}

.banner {
  height: auto;
  max-width: 100%;
}

.row {
  display: flex;
  gap: 15px;
  padding-bottom: 1em;
  border-bottom: solid 1px #00000080;
}

@media (width < 1200px) {
  .row {
    flex-wrap: wrap;
  }
}

@media (width < 768px) {
  .presskit {
    padding: 15px;
    flex-direction: column;
  }
}

.row:last-child {
  border-bottom: 0;
}

.row:has(.assets) {
  flex-direction: column;
}

.container h4 {
  margin-bottom: 0;
}

.assets {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.assets iframe {
  width: 100%;
  height: 200px;
}

.assets a {
  height: fit-content;
  align-self: center;
  justify-self: center;
}

.assets img {
  width: 100%;
  background-color: #00000020;
  height: auto;
}
