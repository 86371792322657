.icon {
  transition: 500ms;
  height: 2.5rem;
  width: 2.5rem;
  filter: brightness(0) invert(1) drop-shadow(2px 2px 2px #222);
}

.a {
  text-decoration: none;
}

.button {
  height: 3.5rem;
  border-radius: 10rem;
  color: black;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
  font-size: 16px;
  filter: brightness(1) invert(0) drop-shadow(2px 2px 2px #222);
}

.button:hover {
  transition: 500ms;
  color: white;
  background-color: black;
  .icon {
    filter: brightness(0) invert(1) drop-shadow(2px 2px 2px #222);
  }
}

.button:hover .icon {
  filter: brightness(0) invert(1) drop-shadow(2px 2px 2px #222);
}

/* large screens */
@media (width >= 1280px) {
  .icon:hover {
    transition: 500ms;
    filter: brightness(1) invert(0) drop-shadow(2px 2px 2px #222);
  }
}

.button .icon {
  filter: none;
}
