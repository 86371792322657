.blackTopGradient {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 10rem;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 2%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.navigationBarMobile {
  padding: 1rem 4rem;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 2rem;
}

.navigationBarMobileMenuOpen {
  background-color: #6b8593;
  color: black;
}

.navigationBar {
  display: none;
  padding: 1rem 4rem;
  position: relative;
  z-index: 2;
}

.navigationBar .logo,
.navigationBarMobile .logo {
  width: 100px;
  height: 100px;
}

.left,
.leftMobile {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.leftMobile {
  filter: drop-shadow(1px 1px 1px #222);
}

.middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.middle ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.middle li {
  list-style: none;
}

.middle li a {
  text-decoration: none;
  color: white;
  padding: 1rem;
}

a[class="active"] {
  border-bottom: 2px solid white !important;
}

.right,
.rightMobile {
  display: flex;
  align-items: center;
  justify-content: right;
}

.mobileMenuList {
  display: flex;
  position: absolute;
  z-index: 2;
}

.mobileMenuList ul {
  position: absolute;
  height: 90vh;
  width: 100vw;
  left: 0;
  top: 0;
  background-color: #6b8593;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobileMenuList li {
  filter: drop-shadow(1px 1px 1px #222);
  list-style: none;
  margin: 3vw 5vw;
  font-size: 6.6vmin;
  text-align: center;
  list-style: none;
  color: black;
}

.mobileMenuList a:visited {
  color: black;
}

.mobileMenuList li a {
  text-decoration: none;
  padding: 1rem;
}

.mobileMenuListSocials {
  color: black;
  position: relative;
  z-index: 2;
}

.mobileMenuListSocials .icon {
  height: 200px;
}

/* large screens */
@media (width > 1280px) {
  .navigationBar {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    color: white;
    font-size: 1.5rem;
    filter: drop-shadow(2px 2px 2px #222);
  }

  .navigationBarMobile {
    display: none;
  }

  .mobileMenuList {
    display: none;
  }
}

/* small screens */
@media (width <= 768px) {
  .navigationBarMobile {
    padding: 1rem 1rem;
    font-size: 1.5rem;
  }

  .rightMobile img {
    height: 1.5rem;
    width: 1.5rem;
  }
}
